import React from "react"
import Layout from "../components/layout"
// import ServicesTemplate from '../templates/services'

const IndexPage = () => (
  <Layout>
    {/* <ServicesTemplate /> */}
  </Layout>
)

export default IndexPage